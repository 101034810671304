import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionQualifyRepForm from "../../components/sections/section-qualify-rep-form"

const PageRepresentativeQualifyForm = () => {
  const data = useStaticQuery(graphql`
    query PageRepresentativeQualifyFormQuery {
      imgHero: file(relativePath: { eq: "backgrounds/hero-client-res-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgSrc = getSrc(data.imgHero)

  return (
    <Layout isLandingPage isApply isNewTestiApplyPage isContactPage>
      <Seo
        title="Apply to be an SRA Representative"
        description="If you qualify as an SRA Representative, apply here."
        image={imgSrc}
      />

      <SectionQualifyRepForm />
    </Layout>
  )
}

export default PageRepresentativeQualifyForm
