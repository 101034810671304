import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HubspotForm from 'react-hubspot-form'

const SectionQualifyRepForm = () => {
  const data = useStaticQuery(graphql`
    query SectionQualifyRepFormQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
      <GatsbyImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
        }}
        alt="hero image"
        image={imgDefault}
        formats={["auto", "webp", "avif"]}
      />

      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 mb-14 xl:mb-6">
          <div className="flex flex-col justify-center">
            <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-6 xl:mt-4">
              Apply to be an SRA Representative
            </h2>

            <div className="bg-white w-11/12 max-w-lg mt-12 mx-auto border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg">
              <HubspotForm
                portalId='23182726'
                formId='3ea06b63-05df-476a-b87c-f8703514c6d0'
                loading={<div className='italic'>Loading apply form...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionQualifyRepForm
